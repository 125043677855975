import React from "react";
import Layout from "../components/layout/Layout";
import FaqContent from "../components/FaqContent";
const Faq = () => {
    return <>
        <Layout title={'FAQ'}>
            <FaqContent/>
        </Layout>
    </>
}
export default Faq;