import React from "react";

const FaqContent = () => {

    return (
        <>
            <div className="container content-space-t-3">
                <div className="w-lg-65 text-center mx-lg-auto mb-5 mb-sm-7 mb-lg-10">
                    <h2>Questions Fréquemment posées.</h2>
                </div>
                <div className="w-lg-75 mx-lg-auto">
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">01.</span>
                                                Comment puis je acheter en ligne et me faire livrer en Afrique ?
                                            </h4>
                                            <div className="card-text">
                                                Pour acheter en ligne des articles du monde entier et se faire livrer
                                                partout en Afrique, vous avez le choix entre toutes nos offres:
                                                <ul>
                                                    <li>Offre luxe</li>
                                                    <li>Offre zen</li>
                                                    <li>Offre flex</li>
                                                    <li>Offre chill</li>
                                                    <li>Offre fret</li>
                                                </ul>
                                                Choisissez l’offre qui vous convient la mieux et procédez à vos achats
                                                en ligne avec Afrisends. Le site enverra ensuite votre commande à notre
                                                entrepôt qui sera ensuite réexpédiée chez vous par nos soins.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">02.</span>
                                                Je n'ai pas de carte bancaire, pouvez vous acheter à ma place ?
                                            </h4>
                                            <p className="card-text">
                                                Oui, il vous suffit de choisir entre les offres Zen et luxe, et de nous
                                                contacter par whatsapp au <span
                                                className="text-primary">+33767622662</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">03.</span>
                                                Comment vous faire parvenir les liens ?
                                            </h4>
                                            <p className="card-text">
                                                Par whatsapp au <span className="text-primary">+33767622662</span> ou
                                                par mail à <span className="text-primary">commande@afrisends.com</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">04.</span>
                                                Comment puis je obtenir l'adresse d'Afrisends pour commander en ligne ?
                                            </h4>
                                            <p className="card-text">
                                                Les adresses de réexpéditions Afrisends sont disponibles à partir de
                                                votre espace client.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">05.</span>
                                                Comment dois-je notifier ma commande à Afrisends ?
                                            </h4>
                                            <p className="card-text">
                                                Pour les commandes en ligne effectuées par vos soins, la confirmation de
                                                commande et la facture doivent nous être obligatoirement envoyé par
                                                <span className="text-primary"> WhatsApp</span> afin que nous puissions
                                                effectuer la réexpédition chez vous.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">06.</span>
                                                Qu’est-ce que le réajustement de poids ?
                                            </h4>
                                            <p className="card-text">
                                                Une fois votre <span
                                                className="text-primary">colis</span> reçu, nous vous ferons parvenir
                                                une facture
                                                comprenant les frais d’envoi au poids réel et les frais de gestion.
                                                En cas de colis <span
                                                className="text-primary">volumineux</span> volumineux , le poids retenu
                                                sera la valeur la plus
                                                élevée entre le poids et le volume.
                                                Outils de calcul poids volumétrique
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">07.</span>
                                                Comment suis-je informé(e) de la réception d'un colis à votre entrepôt ?
                                            </h4>
                                            <p className="card-text">
                                                A la réception de votre colis un <span
                                                className="text-primary">message</span> de réception vous sera transmis
                                                par Afrisends ainsi que la facture ajustée des frais à payer.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">08.</span>
                                                Comment se fait le paiement ?
                                            </h4>
                                            <div className="card-text">
                                                Le paiement s’effectue toujours avant la commande ou l’envoi du produit
                                                à votre destination. Nous disposons de plusieurs moyens de paiement en
                                                fonction de votre situation géographique
                                                <ul>
                                                    <li>Orange Money</li>
                                                    <li>Paypal</li>
                                                    <li>Virement Bancaire</li>
                                                    <li>Lien de paiement direct par carte bancaire</li>
                                                    <li>Western union</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">09.</span>
                                                Puis-je envoyer un colis de moins d'1 KG ?
                                            </h4>
                                            <p className="card-text">
                                                Vous pouvez envoyer les colis de moins d’un 1kg, cependant le poids
                                                minimum facturé est de 500g au delà vous serez facturé de 1kg
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">10.</span>
                                                Il y’ a-t-il un forfait spécial pour les envois d’objets de luxe ou
                                                d’électronique ?
                                            </h4>
                                            <p className="card-text">
                                                Effectivement, pour vos objets de luxe ou électronique un forfait
                                                spécial est appliqué.
                                                Dites nous en plus par message (whatsapp ou mail)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">11.</span>
                                                Puis-je commander des colis lourds ?
                                            </h4>
                                            <p className="card-text">
                                                Oui, nous expédions tout type de colis (poids et volume confondus). Le
                                                choix du mode d’envoi entre le fret aérien et maritime vous reviendra.
                                                Nous expédions par fret maritime <span className="text-primary">deux fois</span> par
                                                mois.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">12.</span>
                                                Comment est calculé le poids volume pour le maritime ?
                                            </h4>
                                            <p className="card-text">
                                                Pour les envois maritime le tarif est calculé selon le poids
                                                volumétrique. Ce poids est calculé ainsi : Longueur x Largeur x hauteur
                                                (en cm) / 6000. Le résultat trouvé devrait être multiplié par le prix au
                                                KG.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-file-earmark-check text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">13.</span>
                                                Avez-vous une offre adaptée aux professionnels ?
                                            </h4>
                                            <p className="card-text">
                                                En effet, les professionnels bénéficient par défaut d’un tarif
                                                préférentiel.
                                                Dites-nous en plus sur votre activité et demandez un devis personnalisé.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-clock-history text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">14.</span>
                                                Pouvez-vous vous occuper des formalités douanières ?
                                            </h4>
                                            <p className="card-text">
                                                Oui, nous prenons en charge <span className="text-primary">le dédouanement</span> de
                                                votre marchandise à l'arrivée (Sauf si vous choisissez une option rendu
                                                aéroport ou rendu port).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">15.</span>
                                                Quels sont les produits interdits ou réglementés ?
                                            </h4>
                                            <p className="card-text">
                                                <a target="_blank"
                                                   href="https://www.dhl.com/fr-fr/home/nos-divisions/fret/service-client/articles-interdits-et-marchandises-dangereuses.html"
                                                   className="text-primary">Produits interdits</a><br/>
                                                D’après la réglementation du transport par fret aérien ou maritime,
                                                certains produits comme les parfums, les produits contenant une batterie
                                                au lithium, les aérosols dont les déodorants, les denrées alimentaires
                                                peuvent être soumis à des restrictions. D'autres produits sont
                                                strictements interdits comme les armes, stupéfiants, drogue etc.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">16.</span>
                                                Puis-je commander sur plusieurs sites différent et tout recevoir en même
                                                temps ?
                                            </h4>
                                            <p className="card-text">
                                                Oui, il vous suffit de nous envoyer les liens de vos différents articles
                                                pour que Afrisends passe les <span
                                                className="text-primary">commandes</span> . Pour les commandes en ligne
                                                effectuées par vos soins, la confirmation de commande et la facture
                                                doivent nous être obligatoirement par WhatsApp pour que nous puissions
                                                effectuer l'envoi.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="list-unstyled list-timeline list-py-3">
                        <li className="list-timeline-item">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-pencil-square text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">17.</span>
                                                Quel est le risque si je ne préviens pas Afrisends concernant une
                                                commande passée ou si je ne mets pas tous les éléments de l'adresse ?
                                            </h4>
                                            <p className="card-text">
                                                L'adresse doit être renseignée comme indiquée et comme fournit par
                                                Afrisends. Votre code client nous permet d'identifier votre colis dès
                                                son arrivée, le non respect de cette procédure peut retarder l'envoi de
                                                votre commande. Les autres mentions sont tout aussi importantes et
                                                doivent obligatoirement être renseigné lors de votre commande.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li className="list-timeline-item ms-auto">
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="d-flex">
                                        <div className="flex-shrink-0">
                                            <i className="bi-hand-thumbs-up text-dark fs-2"/>
                                        </div>
                                        <div className="flex-grow-1 ms-4">
                                            <h4 className="card-title">
                                                <span className="fs-2 text-primary">18.</span>
                                                En combien de temps vais-je recevoir ma commande ?
                                            </h4>
                                            <div className="card-text">
                                                Le délai d’expédition moyen d’Afrisends après la réception de vos colis
                                                à notre entrepôt est de <span
                                                className="text-primary">5 jours ouvrés</span>.<br/>
                                                Cependant, ce délai peut être rallongé en fonction de plusieurs facteurs
                                                :
                                                <ul>
                                                    <li>Du délai de livraison de votre fournisseur marchand de base
                                                        (amazon, aliexpress, zara…) à nos entrepôts
                                                    </li>
                                                    <li>Vous souhaitez peut être regrouper tous vos colis pour faire des
                                                        économies
                                                    </li>
                                                    <li>Votre choix d’expédition (standard ou express)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default FaqContent;
